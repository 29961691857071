import type { AppFeature } from './types'

export const features: AppFeature[] = [
  {
    title: 'Seamless communication',
    subtitle:
      'Connect your email and chat with customers, assign to teammates, and manage tickets in one, unified inbox.  ',
    icon: 'bubbles',
  },
  {
    title: 'Assign tickets to teammates',
    subtitle: 'You don’t need to handle all stuff yourself. You have a team!',
    icon: 'hire',
  },
  {
    title: 'Create internal notes',
    subtitle: 'Secret messages only for team members in your workspace.',
    icon: 'note1',
  },
  {
    title: 'Build custom /shortcuts',
    subtitle: 'Do routine things 10x times faster with custom shortcuts',
    icon: 'codeBrackets',
  },
]
