import { reactive, toRefs } from 'vue'
import { TaskStatus } from './models/taskStatus'
import { Team } from './models/team'
import { useTasksTeamStore } from './teamStore'
import { useWorkspaceApi } from '@/composables/api'

interface TeamStatusState {
  selectedTeam: Team | null
  statuses: TaskStatus[]
  selectedStatus: TaskStatus | undefined
  isAddingStatus: boolean
  newStatusName: string
}

export function useChangeTeam() {
  const state = reactive<TeamStatusState>({
    selectedTeam: null,
    statuses: [],
    selectedStatus: undefined,
    isAddingStatus: false,
    newStatusName: '',
  })
  const { api } = useWorkspaceApi()
  const tasksTeamStore = useTasksTeamStore()
  const { task } = toRefs(tasksTeamStore)

  const addCustomStatus = async (name: string): Promise<void> => {
    if (!state.selectedTeam) {
      throw new Error('No team selected')
    }

    try {
      const newStatus = await api.task.createTeamStatus(
        state.selectedTeam.id,
        name,
      )
      state.statuses.push(newStatus)
      state.newStatusName = ''
    } catch (error) {
      console.error('Failed to add custom status:', error)
      throw error
    }
  }

  const loadAndAssignTeamStatuses = async (team: Team): Promise<void> => {
    const currentTask = task?.value
    if (!currentTask) {
      throw new Error('No task available')
    }

    try {
      const statuses = await api.task.loadTeamStatuses(team.id)
      currentTask.team = team
      state.statuses = statuses
      state.selectedTeam = team
    } catch (error) {
      console.error('Failed to load team statuses:', error)
      throw error
    }
  }

  const setStatusAndChangeTeam = async (
    status: TaskStatus,
  ): Promise<TaskStatus[]> => {
    if (!state.selectedTeam) {
      throw new Error('No team selected')
    }

    try {
      state.selectedStatus = status
      tasksTeamStore.updateViewTeamChange(state.selectedTeam, status)
      const statuses = await api.task.loadTeamStatuses(state.selectedTeam.id)
      return statuses
    } catch (error) {
      console.error('Failed to set status and change team:', error)
      throw error
    } finally {
      resetState()
    }
  }

  const resetState = () => {
    state.selectedTeam = null
    state.statuses = []
    state.selectedStatus = undefined
    state.isAddingStatus = false
    state.newStatusName = ''
  }

  return {
    state,
    addCustomStatus,
    loadAndAssignTeamStatuses,
    setStatusAndChangeTeam,
    resetState,
  }
}
