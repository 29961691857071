<script setup lang="ts">
import { computed, reactive, toRefs, watch } from 'vue'
import { useTasksTeamStore } from '../../teamStore'
import { useTasksStore } from '../../store'
import StageIndicator from '../StageIndicator.vue'
import {
  AssigneeMenu,
  Avatar,
  Button,
  DatePicker,
  EmptyView,
  Icon,
  MenuItem,
  Popover,
  ResourceSelector,
  Tag,
  Skeleton,
  TextField,
} from '@/components/common'
import { TaskPriorityMenu } from '../constants'
import { TaskStatus } from '../../models/taskStatus'
import { useWorkspaceApi } from '@/composables/api'
import PriorityIndicator from '../PriorityIndicator.vue'
import { DateTime } from 'luxon'
import { useChangeTeam } from '../../useChangeTeam'

const { api, isStatus } = useWorkspaceApi()
const store = useTasksStore()
store.tags.length === 0 && store.loadTags()

const tasksTeamStore = useTasksTeamStore()
const { task } = toRefs(tasksTeamStore)

if (tasksTeamStore.tasks.length === 0) {
  tasksTeamStore.loadTasks()
}

const state = reactive<{
  statuses: TaskStatus[]
}>({
  statuses: [],
})

const taskPriority = computed(() => {
  return TaskPriorityMenu.find((p) => p.id === task?.value?.priority)
})

const startDate = computed(
  () =>
    task?.value?.startDate && DateTime.fromISO(task.value.startDate).toJSDate(),
)

const endDate = computed(
  () => task?.value?.dueDate && DateTime.fromISO(task.value.dueDate).toJSDate(),
)

watch(
  () => task,
  (newTask) => {
    newTask?.value &&
      api.task.loadTeamStatuses(newTask.value.teamId).then((data) => {
        state.statuses = data
      })
  },
  { immediate: true },
)
const {
  addCustomStatus,
  loadAndAssignTeamStatuses,
  setStatusAndChangeTeam,
  state: teamChangeState,
} = useChangeTeam()
</script>
<template>
  <div v-if="tasksTeamStore.isStatus('loading') && isStatus('loading')">
    <div :class="$style.container">
      <Skeleton v-for="n in 5" :key="n" :height="20" />
    </div>
  </div>
  <template v-else>
    <div :class="$style.container">
      <Popover>
        <template #content>
          <ResourceSelector
            v-if="!teamChangeState.selectedTeam"
            :items="store.teams"
            :selected="task?.team"
            item-key="id"
            title-key="name"
            selection="single"
            @update="loadAndAssignTeamStatuses"
          />
          <div v-else>
            <p :class="$style.selectStatusLabel">
              Assign a status to this task
            </p>
            <ResourceSelector
              :items="teamChangeState.statuses"
              :selected="teamChangeState.selectedStatus"
              item-key="id"
              title-key="name"
              selection="single"
              @update="setStatusAndChangeTeam"
            >
              <template #empty="{ search }">
                <MenuItem
                  v-if="search.length"
                  :title="`Create new status '${search}'`"
                  @click="addCustomStatus(search)"
                />
                <div v-else>
                  <Button
                    v-if="!teamChangeState.isAddingStatus"
                    theme="neutral"
                    variant="ghost"
                    size="2"
                    @click="() => (teamChangeState.isAddingStatus = true)"
                    >Create new status</Button
                  >
                  <div
                    v-if="teamChangeState.isAddingStatus"
                    :class="$style.addStatusTextField"
                  >
                    <TextField
                      ref="inputRef"
                      v-model="teamChangeState.newStatusName"
                      :class="$style.textField"
                      variant="soft"
                      @keyup.enter="
                        () => {
                          addCustomStatus(teamChangeState.newStatusName)
                          teamChangeState.isAddingStatus = false
                        }
                      "
                      @keyup.escape="
                        () => {
                          teamChangeState.isAddingStatus = false
                        }
                      "
                    >
                    </TextField>
                    <Button
                      icon="crossSmall"
                      variant="ghost"
                      theme="neutral"
                      size="2"
                      @click="
                        () => {
                          teamChangeState.isAddingStatus = false
                        }
                      "
                    />
                    <Button
                      icon="squareCheck"
                      variant="ghost"
                      theme="neutral"
                      size="2"
                      @click="
                        () => {
                          addCustomStatus(teamChangeState.newStatusName)
                          teamChangeState.isAddingStatus = false
                        }
                      "
                    />
                  </div>
                </div>
              </template>
            </ResourceSelector>
          </div>
        </template>
        <Button size="2" variant="ghost" theme="neutral" :class="$style.button">
          <template #icon>
            <Icon v-if="task?.team?.icon" :icon="task.team.icon" :size="16" />
          </template>
          {{ task?.team?.name }}
        </Button>
      </Popover>
      <Popover>
        <template #content>
          <ResourceSelector
            :items="state.statuses"
            :selected="task?.status"
            item-key="id"
            title-key="name"
            selection="single"
            @update="
              (data) => {
                tasksTeamStore.taskViewUpdate({
                  status: data,
                })
              }
            "
          />
        </template>
        <Button size="2" variant="ghost" theme="neutral" :class="$style.button">
          <template #icon>
            <StageIndicator
              :size="16"
              :stage="task?.status?.order || 0"
              :total-stages="task?.team?.statuses?.length || 5"
            />
          </template>
          {{ task?.status?.name }}
        </Button>
      </Popover>
      <Popover>
        <template #content>
          <ResourceSelector
            :items="TaskPriorityMenu"
            :selected="taskPriority"
            item-key="id"
            title-key="name"
            selection="single"
            @update="
              (data) => {
                tasksTeamStore.taskViewUpdate({
                  priority: data,
                })
              }
            "
          >
            <template #icon="{ item }">
              <PriorityIndicator v-if="item" :priority="item.id" />
            </template>
          </ResourceSelector>
        </template>
        <Button
          icon="unassignedIcon"
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.button"
        >
          <template #icon>
            <PriorityIndicator :priority="taskPriority?.id || 0" :size="16" />
          </template>
          {{ taskPriority?.name }}</Button
        >
      </Popover>
      <Popover>
        <template #content>
          <AssigneeMenu
            :assignees="task?.assignees?.length ? [task?.assignees[0]] : []"
            :single-selection="true"
            @update="
              (data) => {
                tasksTeamStore.taskViewUpdate(
                  {
                    assignee: data[0],
                  },
                  true,
                )
              }
            "
          />
        </template>
        <Button size="2" variant="ghost" theme="neutral" :class="$style.button">
          <template #icon>
            <Avatar
              v-if="task?.assignees?.length"
              :title="task.assignees[0].name"
              :src="task.assignees[0].avatar"
              :size="16"
            />
            <Icon v-else icon="unassignedIcon" :size="16" />
          </template>
          {{
            task?.assignees?.length ? task?.assignees[0]?.name : 'Unassigned'
          }}</Button
        >
      </Popover>
      <Popover placement="left-start">
        <template #content>
          <DatePicker
            v-model="startDate"
            :max-date="endDate"
            inline
            :enable-time-picker="false"
            @update:model-value="
              (data) => {
                if (data instanceof Date)
                  tasksTeamStore.taskViewUpdate({
                    start: data,
                  })
              }
            "
          />
        </template>
        <Button
          icon="calenderAdd"
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.button"
          >{{
            startDate ? `Start: ${startDate.toDateString()}` : 'Add start date'
          }}</Button
        >
      </Popover>
      <Popover placement="left-start">
        <template #content>
          <DatePicker
            v-model="endDate"
            :min-date="startDate"
            inline
            :enable-time-picker="false"
            @update:model-value="
              (data) => {
                if (data instanceof Date)
                  tasksTeamStore.taskViewUpdate({
                    end: data,
                  })
              }
            "
          />
        </template>
        <Button
          icon="calendar2"
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.button"
          >{{
            endDate ? `End: ${endDate.toDateString()}` : 'Add due date'
          }}</Button
        >
      </Popover>
    </div>
    <div :class="$style['tags-container']">
      <Popover>
        <template #content>
          <ResourceSelector
            :items="store.tags"
            :selected="task?.tags"
            item-key="id"
            title-key="name"
            selection="multi"
            @update="
              (data) => {
                tasksTeamStore.taskViewUpdate({
                  tags: data,
                })
              }
            "
          >
            <template #empty="{ search }">
              <MenuItem
                v-if="search.length"
                :title="`Create new tag '${search}'`"
                @click="store.createTag(search)"
              />
              <EmptyView v-else title="No tags found" />
            </template>
          </ResourceSelector>
        </template>
        <div :class="$style.title">
          <p>Tags</p>
          <Button size="2" variant="ghost" theme="neutral" icon="plusSmall" />
        </div>
        <div :class="$style.tags">
          <Tag
            v-for="tag in task?.tags"
            :key="tag.id"
            size="2"
            variant="outline"
            >{{ tag.name }}</Tag
          >
        </div>
      </Popover>
    </div>
  </template>
</template>
<style module lang="scss">
.container {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .detail-item {
    display: flex;
    column-gap: 4px;
    align-items: center;
    padding: 6px 8px;

    &.item-due {
      color: var(--danger-11);
    }

    p {
      @extend .medium-3;
    }
  }
}

.addStatusTextField {
  width: 100%;
  display: flex;
  .textField {
    width: 100%;
  }
}
.selectStatusLabel {
  @extend .medium-3;
  padding: 8px;
  padding-bottom: 0px;
}
.tags-container {
  border-top: 1px solid var(--neutral-alpha-4);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: var(--neutral-11);
    }
  }

  .tags {
    display: flex;
    column-gap: 4px;
    margin-top: 16px;
  }
}
</style>
