import { useWorkspaceApi } from '@/composables/api'
import { Tag } from '@/models/tag'
import type User from '@/models/user'
import { tracker } from '@/utils'
import { generateFilters } from '@/utils/tasks'
import { defineStore } from 'pinia'
import type { Task } from './models/task'
import type { TaskStatus } from './models/taskStatus'
import type { Team } from './models/team'
import { TaskPriorityItem, TaskSearch, UpdateTaskRequest } from './types'

export interface DueDateItem {
  label: string
  date: string
}

export interface TaskRequest {
  title: string
  details: string
  team?: Team
  status?: TaskStatus
  assignee?: User
  tags: Tag[]
  start?: Date
  end?: Date
  priority: TaskPriorityItem
  parentId?: number
  attachments: {
    files: File[]
    images: File[]
  }
}

interface TaskStore extends ReturnType<typeof useWorkspaceApi> {
  id?: number
  team?: Team
  statuses: TaskStatus[]
  tasks: Task[]
  task?: Task
  members: User[]
  isAddTaskOpen: boolean
  selectedStatus?: TaskStatus
}

export const useTasksTeamStore = defineStore('tasksTeam', {
  state: (): TaskStore => ({
    team: undefined,
    statuses: [],
    tasks: [],
    members: [],
    isAddTaskOpen: false,
    selectedStatus: undefined,
    ...useWorkspaceApi(),
  }),
  getters: {
    stageTaskMap() {
      const map: Record<number, Task[]> = {}
      this.tasks.forEach((task) => {
        if (!map[task.statusId]) {
          map[task.statusId] = []
        }
        map[task.statusId].push(task)
      })
      return map
    },
  },
  actions: {
    createTask(payload: TaskRequest) {
      return (
        payload.status &&
        this.api.task
          .createTask({
            title: payload.title,
            description: payload.details,
            statusId: payload.status.id,
            priority: payload.priority.id,
            startDate: payload.start?.toISOString(),
            dueDate: payload.end?.toISOString(),
            assignees: payload.assignee ? [payload.assignee.id] : [],
            tags: payload.tags.map((t) => t.id),
            attachments: payload.attachments,
            parentId: payload.parentId,
          })
          .then((data) => {
            this.tasks.push(data)

            tracker.trackEvent('task_created', {
              task: `${data.team?.code}-${data.number}`,
            })

            return data
          })
      )
    },
    taskViewUpdate(payload: UpdateTaskRequest, isAssigneeUpdate = false) {
      if (this.task) {
        const task = {
          ...this.task,
          ...{
            ...(payload.status && {
              statusId: payload.status.id,
              status: payload.status,
            }),
            ...(isAssigneeUpdate && {
              assignees: payload.assignee ? [payload.assignee] : [],
            }),
            ...(payload.priority && { priority: payload.priority.id }),
            ...(payload.start && {
              startDate: payload.start?.toISOString(),
            }),
            ...(payload.end && { dueDate: payload.end?.toISOString() }),
            ...(payload.tags && { tags: payload.tags }),
            ...(payload.team && {
              teamId: payload.team.id,
              team: payload.team,
            }),
          },
        }

        this.task = task

        const tasks = this.tasks.map((item) =>
          item.id === task.id ? { ...item, ...task } : item,
        )
        this.tasks = tasks

        this.api.task.updateTask(
          {
            ...(payload.status && { statusId: payload.status.id }),
            ...(isAssigneeUpdate && {
              assignees: payload.assignee ? [payload.assignee.id] : [],
            }),
            ...(payload.priority && { priority: payload.priority.id }),
            ...(payload.start && {
              startDate: payload.start?.toDateString(),
            }),
            ...(payload.end && { dueDate: payload.end?.toDateString() }),
            ...(payload.tags && { tags: payload.tags.map((t) => t.id) }),
            ...(payload.team && { teamId: payload.team.id }),
          },
          this.task.id,
        )
      }
    },
    async updateViewTeamChange(team: Team, status: TaskStatus) {
      if (!this.task) return

      const prevTeam = this.team
      const prevStatus = this.task.status

      try {
        await this.api.task.updateTask(
          { teamId: team.id, statusId: status.id },
          this.task.id,
        )
        const updatedTask = {
          ...this.task,
          teamId: team.id,
          team,
          status,
        }
        this.task = updatedTask

        this.tasks = this.tasks.filter((task) => task.id !== updatedTask.id)
      } catch (error) {
        if (!prevTeam) return
        this.task = {
          ...this.task,
          team: prevTeam,
          teamId: prevTeam.id,
          status: prevStatus,
        }
      }
    },
    loadTasks(search?: TaskSearch) {
      return (
        this.team &&
        this.api.task
          .loadTasks(
            generateFilters({
              ...search,
              team: this.team,
            }),
          )
          .then((response) => (this.tasks = response))
      )
    },
    loadTeam(id: number) {
      this.id = id
      return this.api.task.loadTeam(id).then((data) => {
        this.team = data
        this.statuses = data.statuses || []
        this.members = data.members || []
      })
    },
    addTeamMember(teamId: number, user: User) {
      if (teamId === this.team?.id) {
        this.members.push(user)
      }
    },
    removeTeamMember(teamId: number, userId: number) {
      if (teamId === this.team?.id) {
        const newMembers = this.members.filter((member) => member.id !== userId)

        this.members = newMembers
      }
    },
    moveStatus(oldIndex: number, newIndex: number) {
      const newStatuses = [...this.statuses]
      const item = newStatuses[oldIndex]
      newStatuses.splice(oldIndex, 1)
      newStatuses.splice(newIndex, 0, item)
      this.statuses = newStatuses.map((status, index) => ({
        ...status,
        order: index,
      }))
      this.team &&
        this.api.task.updateTeamStatusOrder(
          this.team.id,
          this.statuses.map((s) => s.id),
        )
    },
    addStatus() {
      this.team?.id &&
        this.api.task
          .createTeamStatus(this.team?.id, `Untitled Status`)
          .then((data) => {
            this.statuses.push(data)
          })
    },
    updateStatus(stage: TaskStatus, name: string) {
      const index = this.statuses.findIndex((s) => s.id === stage.id)
      Object.assign(this.statuses[index], {
        name,
      })
      this.team?.id &&
        this.api.task.updateTeamStatus(this.team.id, stage.id, { name })
    },
    deleteStatus(id: number) {
      const updated = [...this.statuses].filter((c) => c.id !== id)

      this.statuses = updated.map((stage, index) => ({
        ...stage,
        order: index,
      }))

      this.team?.id && this.api.task.deleteTeamStatus(this.team.id, id)
    },
    toggleStatusVisibility(id: number, isVisible: boolean) {
      const index = this.statuses.findIndex((s) => s.id === id)
      Object.assign(this.statuses[index], {
        isVisible,
      })
      this.team?.id &&
        this.api.task.updateTeamStatus(this.team.id, id, { isVisible })
    },
    moveTaskToStage(
      task: Task,
      status: TaskStatus,
      rank?: string,
      order?: number,
    ) {
      const index = this.tasks.findIndex((t) => t.id === task.id)
      Object.assign(this.tasks[index], {
        statusId: status.id,
        order: order || 0,
        rank,
      })

      this.api.task.updateTask(
        {
          statusId: status.id,
          rank,
        },
        task.id,
      )
    },
    loadTask(teamId: number, taskNumber: number) {
      return this.api.task
        .getTaskByTeamIdAndNumber(teamId, taskNumber)
        .then((data) => {
          this.task = data
        })
    },
    assignTask(task: Task, user: User) {
      const index = this.tasks.findIndex((c) => c.id === task.id)
      Object.assign(this.tasks[index], {
        assignees: [user],
      })

      this.api.task.updateTask(
        {
          assignees: [user.id],
        },
        task.id,
      )
    },
    deleteTask(taskId: number) {
      if (this.task?.id === taskId) this.task = undefined

      const updatedTasks = [...this.tasks].filter((t) => t.id !== taskId)
      this.tasks = updatedTasks

      this.team?.id && this.api.task.deleteTask(taskId)
    },
  },
})
