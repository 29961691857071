<script setup lang="ts">
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { useWorkspaceStore } from '@/store/workspace'
import { computed, reactive } from 'vue'
import { useTasksStore } from './store'
import { Button, EmptyView, Icon } from '@/components/common'
import Row from './components/Teams/Row.vue'

const store = useTasksStore()

const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore

const newTeam = reactive({
  isOpen: false,
  name: '',
})

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Tasks`,
    url: { name: 'Tasks' },
  },
  {
    title: 'Teams',
  },
])

const handleCreate = () => {
  newTeam.name.length &&
    store.createTeam(newTeam.name).then(() => {
      newTeam.name = ''
      newTeam.isOpen = false
    })
}
</script>
<template>
  <Page>
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <Button
        icon="plusSmall"
        size="2"
        variant="outline"
        theme="neutral"
        @click="newTeam.isOpen = true"
        >Create team</Button
      >
    </template>
    <EmptyView
      v-if="
        store.teams.length === 0 &&
        store.isStatus('loaded') &&
        !newTeam.isOpen
      "
      :class="$style.empty"
      icon="arCube1"
      title="You don't have any Teams yet."
      description="Teams are a great way to organize your tasks in a lists and track various progress stages."
    >
      <Button
        icon="plusSmall"
        size="2"
        variant="outline"
        theme="neutral"
        @click="newTeam.isOpen = true"
        >Create team</Button
      >
    </EmptyView>
    <template v-else>
      <h2 :class="$style.heading">Teams</h2>
      <table :class="$style.table">
        <tbody>
          <Row v-for="team in store.teams" :key="team.id" :team="team" />
          <tr v-if="newTeam.isOpen">
            <td width="32">
              <span :class="$style.pipelineIcon">
                <Icon icon="arCube1" :size="20" />
              </span>
            </td>
            <td colspan="4">
              <input
                v-model="newTeam.name"
                v-focus
                :class="$style.textField"
                placeholder="add name for new project"
                @keypress.enter="handleCreate"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Page>
</template>
<style module lang="scss">
@import url(./components/Teams/common.module.scss);

.heading {
  @extend .semibold-6;
  margin: var(--page-margin) 0px;
}
.empty {
  margin-top: 64px;
}
.table {
  width: 100%;

  tr {
    td {
      padding: 12px 0px;
      vertical-align: middle;
      transition: background-color 0.1s;
      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    &:hover {
      td {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }
}
</style>
