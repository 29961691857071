<script setup lang="ts">
import {
  AssigneeBadge,
  AssigneeMenu,
  Popover,
  ResourceSelector,
  Tag,
} from '@/components/common'
import { type Task } from '../../models/task'
import { useTasksTeamStore } from '../../teamStore'
import PriorityIndicator from '../PriorityIndicator.vue'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'
import { TaskPriorityMenu } from '../constants'
import { TaskUpdateRequest } from '../../api/types'
import { useWorkspaceApi } from '@/composables/api'

const props = defineProps<{
  task: Task
}>()

const router = useRouter()
const store = useTasksTeamStore()
const { api } = useWorkspaceApi()
const taskRef = ref<Task>(props.task)

const urgency = computed<string | undefined>(() => {
  if (!props.task.dueDate) return undefined

  const dueDate = DateTime.fromSQL(props.task.dueDate)

  const tomorrow = DateTime.now().plus({ day: 1 })
  if (tomorrow.hasSame(dueDate, 'day')) return 'Tomorrow'

  const today = DateTime.now()
  if (today.hasSame(dueDate, 'day')) return 'Today'

  return undefined
})

const handleNavigate = () => {
  router.push({
    name: 'TasksTask',
    params: { teamId: props.task.teamId, taskNumber: props.task.number },
  })
}

const taskPriority = computed(() => {
  return TaskPriorityMenu.find((p) => p.id === taskRef.value?.priority)
})

const handleUpdate = (data: Partial<TaskUpdateRequest>) => {
  api.task
    .updateTask(data, taskRef.value.id)
    .then((data: Task) => (taskRef.value = data))
}
</script>
<template>
  <div :class="$style.container">
    <div :class="$style.titleArea">
      <div :class="$style.names" @click="handleNavigate">
        <p :class="$style.number">{{ store.team?.code }}-{{ task.number }}</p>
        <p :class="$style.name">{{ task.title }}</p>
      </div>
      <Popover>
        <template #content>
          <AssigneeMenu
            :assignees="task.assignees"
            :single-selection="true"
            @update="(data) => store.assignTask(task, data[0])"
          />
        </template>
        <AssigneeBadge :users="task.assignees" :avatar-only="true" />
      </Popover>
    </div>
    <div :class="$style['tags-area']">
      <Popover>
        <template #content>
          <ResourceSelector
            :items="TaskPriorityMenu"
            :selected="taskPriority"
            item-key="id"
            title-key="name"
            selection="single"
            @update="(data) => handleUpdate({ priority: data.id })"
          >
            <template #icon="{ item }">
              <PriorityIndicator v-if="item" :priority="item.id" />
            </template>
          </ResourceSelector>
        </template>
        <Tag size="2" variant="outline"
          ><PriorityIndicator :priority="taskRef.priority" :size="16"
        /></Tag>
      </Popover>
      <Tag
        v-for="tag in task.tags"
        :key="tag.id"
        size="2"
        variant="outline"
        @click="handleNavigate"
        >{{ tag.name }}</Tag
      >
      <Tag
        v-if="urgency"
        size="2"
        variant="outline"
        icon="calendar2"
        :class="$style.urgent"
        >Due {{ urgency }}</Tag
      >
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--background-reverse-1);
  box-shadow: 0px 0.3px 0.3px 0.01px rgba(0, 0, 0, 0.16);
  cursor: grab;
  transition: background-color 300ms ease-out;
  &:hover {
    background-color: var(--neutral-alpha-2);
  }
  .titleArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 24px;

    .names {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      row-gap: 2px;
      min-width: 0;
    }

    .number {
      @extend .regular-3;
      color: var(--neutral-11);
    }

    .name {
      @extend .medium-3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tags-area {
    display: flex;
    column-gap: 3px;
    margin-top: 8px;
  }

  .urgent {
    color: var(--danger-alpha-11);
  }
}
</style>
