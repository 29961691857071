<script lang="ts" setup>
import { Activity } from '@/models/activity.ts'
import { computed } from 'vue'
import { last, lowerCase, startCase } from 'lodash'
import { ISODate } from '@/utils/helpers'
import { useTasksTeamStore } from '../../teamStore.ts'
import { TaskPriorityMenu } from '../constants.ts'
import User from '@/models/user.ts'
import { Tag } from '@/models/tag.ts'
const store = useTasksTeamStore()

const props = defineProps<{ activity: Activity }>()
const message = computed(() => {
  const { event, subject, subjectType, properties, description } =
    props.activity
  const entity = lowerCase(last(subjectType.split('\\')))
  let message = ''
  switch (`${entity}.${event}`) {
    case 'team.task.team.changed':
      message = `changed team from ${properties?.old?.name} to ${properties.attributes.name}`
      break
    case 'task.created':
      message = 'created ticket.'
      break
    case 'task.updated':
      Object.keys(properties.attributes).forEach((key) => {
        let subMessage = ''
        switch (key) {
          case 'title':
            subMessage = `changed title to ${properties.attributes[key]}.`
            break
          case 'description':
            subMessage = `updated the description of the ticket.`
            break
          case 'attachments':
            subMessage = `updated the attachments of the ticket.`
            break
          case 'priority':
            subMessage = `changed priority to ${TaskPriorityMenu.find((i) => i.id === properties.attributes.priority)?.name}.`
            break
          case 'status_id':
            {
              const oldStatus = (store.task?.team?.statuses ?? []).find(
                (status) => status.id === properties.old?.status_id,
              )
              const newStatus = (store.task?.team?.statuses ?? []).find(
                (status) => status.id === properties.attributes?.status_id,
              )

              subMessage = `changed status from ${oldStatus?.name} to ${newStatus?.name}.`
            }
            break
          case 'start_date':
          case 'due_date':
            subMessage = `changed ${lowerCase(startCase(key))} to ${properties.attributes[key]}.`
            break
        }
        message = message ? `${message}, ${subMessage}` : subMessage
      })
      break
    case 'user.task.assignees.added':
    case 'user.task.assignees.removed':
      message = `${description} ${(subject as User).name} as assignee to this task.`
      break
    case 'tag.task.tags.added':
    case 'tag.task.tags.removed':
      message = `${description} tag "${(subject as Tag).name}" to this task.`
  }
  return message
})
</script>
<template>
  <div :class="$style['task-activity']">
    <span :class="$style['causer']">
      {{ activity.causer?.name }}
    </span>
    <span :class="$style['message']">{{ ' ' }} {{ message }} {{ ' ' }}</span>
    <span :class="$style['time']">
      {{ ISODate(activity.createdAt, { format: 'LLL dd' }) }}
    </span>
  </div>
</template>

<style module lang="scss">
.task-activity {
  .causer,
  .time {
    @extend .medium-2;
  }

  .message {
    @extend .regular-2;
  }
}
</style>
