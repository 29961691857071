<script setup lang="ts">
import { ref, toRefs } from 'vue'

import { Button } from '@common/index.ts'
import { Task } from '@modules/tasks/models/task.ts'
import AddTask from '@modules/tasks/components/AddTask.vue'
import { useTasksTeamStore } from '@modules/tasks/teamStore.ts'
import SubTaskItem from '@modules/tasks/components/SubTasks/SubTaskItem.vue'

const props = defineProps<{
  task: Task
}>()

const emit = defineEmits(['created'])
const addTaskRef = ref<typeof AddTask>()
const { task } = toRefs(props)
const store = useTasksTeamStore()

const openForm = ref<boolean>(false)

const handleSubtaskCreated = (subTask: Task) => {
  if (addTaskRef.value && !addTaskRef.value.addMore) {
    openForm.value = false
  }
  emit('created', subTask)
}
</script>

<template>
  <div :class="$style.subTasks">
    <div v-if="task.subTasks?.length" :class="$style.header">
      <p>Sub tasks</p>
      <Button
        size="2"
        variant="ghost"
        theme="neutral"
        icon="plusSmall"
        @click="openForm = true"
      />
    </div>
    <Button
      v-if="!openForm && !task.subTasks?.length"
      size="2"
      variant="ghost"
      theme="neutral"
      icon="plusSmall"
      @click="openForm = true"
      >Add sub task</Button
    >
    <ul>
      <SubTaskItem
        v-for="subTask in task.subTasks ?? []"
        :key="subTask.id"
        :sub-task="subTask"
      />
    </ul>

    <div v-if="openForm" :class="$style.form">
      <AddTask
        v-if="store.team"
        ref="addTaskRef"
        :team="store.team"
        :team-editable="false"
        :parent-id="task.id"
        :description-height="50"
        @created="handleSubtaskCreated"
        @cancel="openForm = false"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.subTasks {
  .header {
    @extend .medium-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    border-radius: 12px;
    border: 1px solid var(--neutral-alpha-4);
    background-color: var(--panel-2);
  }
}
</style>
