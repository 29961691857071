<script setup lang="ts">
import type App from '@/models/app'
import { Icon, Badge } from '@/components/common'
import { AppStatus } from '@/models/app'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  app: App
}>()
const { app } = toRefs(props)

const cover = computed(() => `url(${app.value.cover})`)
const statusInfo = computed(() => {
  const { status } = app.value
  if (status === AppStatus.Available)
    return { class: 'badgeAvailable', title: 'Available' }

  if (status === AppStatus.Consideration)
    return { class: 'badgeComing', title: 'In Consideration' }

  if (status === AppStatus.Development)
    return { class: 'badgeDevelopment', title: 'In Development' }

  return { class: 'badgePlanning', title: 'Planning' }
})
</script>
<template>
  <div :class="$style.container">
    <div :class="$style['app-image-container']">
      <div
        v-if="app.status === AppStatus.Available"
        :class="$style.coverImage"
      ></div>
      <div v-else :class="$style['coming-soon']">
        <Icon icon="brokenChainLink" />
        <p :class="$style.title">Coming soon!</p>
        <p :class="$style.subtitle">Stay tuned for future updates!</p>
      </div>
    </div>
    <div :class="$style['app-details']">
      <div :class="$style['app-icon-container']">
        <div :class="$style['icon-wrapper']">
          <Icon :icon="app.icon" :size="24" />
        </div>
      </div>
      <div :class="$style['title-container']">
        <p :class="$style['app-title']">{{ app.name }}</p>
        <Badge size="2" :class="$style[statusInfo.class]">
          {{ statusInfo.title }}
        </Badge>
      </div>
      <p :class="$style['add-description']">
        {{ app.description }}
      </p>
      <p :class="$style['subTitle']">{{ app.subTitle }}</p>
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  padding: 8px 8px 24px 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: none;
  transition: 0.2s box-shadow;

  &:hover {
    box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  }

  .app-image-container {
    padding: 32px 22px 0px 22px;
    width: 100%;
    height: 210px;
    border-radius: 16px;
    background-color: var(--panel-3);
    width: 100%;
    overflow: hidden;

    .coverImage {
      background-image: v-bind('cover');
      background-repeat: no-repeat;
      background-position: start;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
    }
  }

  .coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 210px;
    justify-content: center;
    gap: 4px;

    i {
      margin-bottom: 24px;
    }

    .title {
      @extend .semibold-3;
      color: var(--neutral-12);
    }

    .subtitle {
      @extend .regular-2;
      color: var(--neutral-11);
    }
  }

  .app-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    margin-top: -24px;
    width: 100%;
    padding: 0 18px;

    .app-icon-container {
      border-radius: 12px;
      background: var(--neutral-3);
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-wrapper {
        height: 38px;
        width: 38px;
        background-color: var(--colors-basic-white);
        border-radius: 999px;
        filter: drop-shadow(0px 0px 25.2px rgba(31, 35, 39, 0.14))
          drop-shadow(0px 0.4px 1px rgba(0, 0, 0, 0.08));
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .app-title {
        @extend .semibold-4;
        color: var(--neutral-12);
      }

      .badgeAvailable {
        background-color: var(--success-alpha-3);
        color: var(--success-alpha-11);
      }

      .badgeComing {
        background: var(--neutral-alpha-3);
        color: var(--neutral-alpha-11);
      }

      .badgeDevelopment {
        background: var(--warning-alpha-3);
        color: var(--warning-alpha-11);
      }

      .badgePlanning {
        background: white;
        color: var(--neutral-alpha-11);
        border: 1px solid var(--neutral-alpha-8, rgba(0, 8, 48, 0.27));
      }
    }

    .add-description {
      @extend .regular-3;
      color: var(--neutral-11);
      width: 244px;
    }

    .subTitle {
      @extend .medium-2;
    }

    .app-type {
      @extend .medium-2;
      color: var(--neutral-12);
    }
  }
}
</style>
