<script setup lang="ts">
import {
  AddTag,
  AssigneeMenu,
  Button,
  EmptyView,
  Icon,
  MenuItem,
  Popover,
  ResourceSelector,
  RichEditor,
  DatePicker,
  Tag,
  FileField,
  Avatar,
  Toggle,
} from '@/components/common'
import { useTasksStore } from '../store'
import { computed, inject, reactive, ref, watch } from 'vue'
import StageIndicator from './StageIndicator.vue'
import { Team } from '../models/team'
import { TaskStatus } from '../models/taskStatus'
import { useWorkspaceApi } from '@/composables/api'
import { TaskPriorityMenu } from './constants'
import PriorityIndicator from './PriorityIndicator.vue'
import { TaskRequest, useTasksTeamStore } from '../teamStore'
import { PopupKey } from '@/components/common/types'
import { showToast } from '@/utils'
import Attachments from '@modules/tasks/components/Attachment/Attachments.vue'
import User from '@/models/user'
import { useApplicationStore } from '@/store'

const acceptableFiles = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/mp4',
  'image/*',
]

const emit = defineEmits(['created', 'cancel'])
const props = defineProps<{
  team?: Team
  status?: TaskStatus
  assignee?: User | null
  teamEditable: boolean
  descriptionHeight?: number
  parentId?: number
}>()

const popupClose = inject(PopupKey.hide)
const { api } = useWorkspaceApi()
const teamStore = useTasksTeamStore()
const store = useTasksStore()
const appStore = useApplicationStore()
store.tags.length === 0 && store.loadTags()
const addMore = ref(false)
defineExpose({ addMore })
const state = reactive<{
  statuses: TaskStatus[]
}>({
  statuses: [],
})

const descriptionEditorHeight = computed(() => {
  return `${props.descriptionHeight ?? 200}px`
})

const form = reactive<TaskRequest>({
  title: '',
  details: '',
  team: props.team,
  status: undefined,
  assignee: props.assignee || undefined,
  tags: [],
  parentId: props.parentId,
  priority: TaskPriorityMenu[TaskPriorityMenu.length - 1],
  attachments: {
    files: [],
    images: [],
  },
})

watch(
  () => form.team,
  (value) => {
    form.status = undefined
    value &&
      api.task.loadTeamStatuses(value.id).then((data) => {
        state.statuses = data
        data.length && (form.status = props.status || data[0])
      })
  },
  { immediate: true },
)

const handleCreate = () => {
  if (!form.status)
    return showToast({ type: 'error', text: 'Please select a status' })

  teamStore.createTask(form)?.then((response) => {
    if (!addMore.value) {
      popupClose?.()
    }
    if (addMore.value) {
      resetForm()
    }
    teamStore.selectedStatus = undefined

    response &&
      form.assignee?.id === appStore.user?.id &&
      store.tasks.push(response)
    emit('created', response)
  })
}
const resetForm = () => {
  form.title = ''
  form.details = ''
  form.attachments = {
    files: [],
    images: [],
  }
}
const handleAddMore = (shouldAddMore: boolean) => {
  addMore.value = shouldAddMore
}
const handleFiles = (data: File | File[]) => {
  const files = data as File[]
  form.attachments.files = [
    ...form.attachments.files,
    ...files.filter((f) => !f.type.includes('image')),
  ]
  form.attachments.images = [
    ...form.attachments.images,
    ...files.filter((f) => f.type.includes('image')),
  ]
}

const deleteImage = (index: number) => {
  form.attachments.images.splice(index, 1)
}
const deleteFile = (index: number) => {
  form.attachments.files.splice(index, 1)
}

const handleCancel = () => {
  emit('cancel')
}

const teamColor = computed(() => {
  return form.team?.color || '#1e1f24'
})
</script>
<template>
  <div :class="[!parentId && $style.container, 'popup-content']">
    <Popover v-if="teamEditable" placement="bottom-start">
      <template #content>
        <ResourceSelector
          :items="store.teams"
          :selected="form.team"
          item-key="id"
          title-key="name"
          selection="single"
          @update="(item) => (form.team = item)"
        />
      </template>
      <Button size="1" variant="outline" theme="neutral" :class="$style.team">
        <template #icon>
          <Icon :icon="form.team?.icon || 'arCube1'" />
        </template>
        {{ form.team?.name }}
      </Button>
    </Popover>
    <input
      v-model="form.title"
      :class="$style.title"
      placeholder="Task title"
    />
    <RichEditor
      v-model="form.details"
      :class="$style.details"
      placeholder="Task description..."
      @drop-images="handleFiles"
      @drop-files="handleFiles"
    />

    <Attachments
      :images="form.attachments.images"
      :files="form.attachments.files"
      :removable="true"
      @remove-file="deleteFile"
      @remove-image="deleteImage"
    />

    <div :class="$style.meta">
      <FileField
        multiple
        :accept="acceptableFiles.join(',')"
        @update:model-value="handleFiles"
      >
        <Button
          as="span"
          icon="paperclip2"
          size="1"
          variant="outline"
          theme="neutral"
        />
      </FileField>
      <Popover>
        <template #content>
          <ResourceSelector
            :items="state.statuses"
            :selected="form.status"
            item-key="id"
            title-key="name"
            selection="single"
            @update="(data) => (form.status = data)"
          />
        </template>
        <Button
          size="1"
          variant="outline"
          theme="neutral"
          :class="$style.button"
        >
          <template #icon>
            <StageIndicator :size="16" :stage="0" :total-stages="5" />
          </template>
          {{ form.status?.name }}
        </Button>
      </Popover>
      <Popover>
        <template #content>
          <ResourceSelector
            :items="TaskPriorityMenu"
            :selected="form.priority"
            item-key="id"
            title-key="name"
            selection="single"
            @update="(data) => (form.priority = data)"
          >
            <template #icon="{ item }">
              <PriorityIndicator v-if="item" :priority="item.id" />
            </template>
          </ResourceSelector>
        </template>
        <Button
          icon="unassignedIcon"
          size="1"
          variant="outline"
          theme="neutral"
          :class="$style.button"
        >
          <template #icon>
            <PriorityIndicator :priority="form.priority.id" :size="16" />
          </template>
          {{ form.priority.name }}</Button
        >
      </Popover>
      <Popover>
        <template #content>
          <AssigneeMenu
            :assignees="form.assignee ? [form.assignee] : []"
            :single-selection="true"
            @update="(data) => (form.assignee = data[0])"
          />
        </template>
        <Button
          size="1"
          variant="outline"
          theme="neutral"
          :class="$style.button"
        >
          <template #icon>
            <Avatar
              v-if="form.assignee"
              :title="form.assignee.name"
              :src="form.assignee?.avatar"
              :size="16"
            />
            <Icon v-else icon="unassignedIcon" :size="16" />
          </template>
          {{ form.assignee?.name || 'Unassigned' }}</Button
        >
      </Popover>
      <Popover placement="auto-start">
        <template #content>
          <DatePicker
            v-model="form.start"
            inline
            :enable-time-picker="false"
            :max-date="form.end"
          />
        </template>
        <Button
          icon="calendarAdd4"
          size="1"
          variant="outline"
          theme="neutral"
          :class="$style.button"
          >{{ form.start?.toDateString() || 'Start date' }}</Button
        >
      </Popover>
      <Popover placement="auto-start">
        <template #content>
          <DatePicker
            v-model="form.end"
            :min-date="form.start"
            inline
            :enable-time-picker="false"
          />
        </template>
        <Button
          icon="calendarAdd4"
          size="1"
          variant="outline"
          theme="neutral"
          :class="$style.button"
          >{{ form.end?.toDateString() || 'Due date' }}</Button
        >
      </Popover>
      <Popover>
        <template #content>
          <ResourceSelector
            :items="store.tags"
            :selected="form.tags"
            item-key="id"
            title-key="name"
            selection="multi"
            @update="(tags) => (form.tags = tags)"
          >
            <template #empty="{ search }">
              <MenuItem
                v-if="search.length"
                :title="`Create new tag '${search}'`"
                @click="store.createTag(search)"
              />
              <EmptyView v-else title="No tags found" />
            </template>
          </ResourceSelector>
        </template>
        <div :class="$style.tags">
          <AddTag v-if="form.tags.length === 0" size="2" icon="plusSmall"
            >Add tag</AddTag
          >
          <Tag
            v-for="tag in form.tags"
            v-else
            :key="tag.id"
            size="2"
            variant="outline"
            >{{ tag.name }}</Tag
          >
        </div>
      </Popover>
    </div>
  </div>
  <div :class="['popup-content', 'popup-actions', $style.actions]">
    <div :class="$style.toggleContainer">
      <Toggle
        :model-value="addMore"
        size="2"
        @update:model-value="handleAddMore"
      />
      <p :class="$style.toggleLabel">Add more</p>
    </div>
    <div :class="$style.actionButtons">
      <Button size="3" variant="outline" theme="neutral" @click="handleCancel">
        Cancel
      </Button>

      <Button
        size="3"
        variant="solid"
        theme="neutral"
        :loading="teamStore.isStatus('posting')"
        @click="handleCreate"
      >
        {{ parentId ? 'Create sub task' : 'Create task' }}
      </Button>
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  width: 680px;
}
.title {
  @extend .semibold-4;
  font-family: inherit;
  width: 100%;
  border: 0px;
  background-color: transparent;
  outline: none;
  padding: 0px;
  margin: 0 0 12px;

  &::placeholder {
    color: var(--neutral-alpha-9);
  }
}
.team {
  margin-bottom: 24px;

  i {
    color: v-bind('teamColor') !important;
  }
}
.toggleContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  .toggleLabel {
    @extend .medium-3;
  }
}
.details {
  overflow: auto;
  min-height: v-bind(descriptionEditorHeight);
  max-height: 200px;
}
.meta {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;

  .button {
    color: var(--neutral-11);
  }
}

.attachments {
  z-index: 1;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}
.tags {
  display: flex;
  gap: 8px;
}
.actions {
  justify-content: space-between;
  .actionButtons {
    gap: 8px;
    display: flex;
    button {
      flex: none;
    }
  }
}
</style>
