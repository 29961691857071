<script setup lang="ts">
import type App from '@/models/app'
import { Icon, Button, Badge, UpgradePopupKey } from '@/components/common'
import { features } from './constants'
import { useWorkspaceStore } from '@/store/workspace'
import { AppStatus } from '@/models/app'
import { computed, inject, toRefs } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  app: App
}>()
const { app } = toRefs(props)

const router = useRouter()
const store = useWorkspaceStore()

const openUpgradePopup = inject(UpgradePopupKey.show)

const cover = computed(() => `url(${app.value.cover})`)

const handleInstall = () => {
  if (app.value?.status !== AppStatus.Available || !app.value.id) return
  if (store.apps.length >= 1 && !store.workspace?.isPremium) {
    openUpgradePopup?.()
  } else {
    const shouldRedirect = store.apps.length === 0
    store.installApp(app.value).then(() => {
      shouldRedirect && router.replace({ name: 'Dashboard' })
    })
  }
}

const handleUninstall = () => {
  store.uninstallApp(app.value.id)
}

const isInstalled = computed(() => {
  const isInstalled = store.apps.find((a) => a.id === app.value.id)
  return !!isInstalled
})
</script>
<template>
  <div :class="$style['app-container']">
    <div :class="$style['app-title-bar']">
      <div :class="$style['title-wrapper']">
        <div :class="$style['app-icon-container']">
          <div :class="$style['icon-wrapper']">
            <Icon :icon="app.icon" />
          </div>
        </div>
        <div :class="$style['app-title']">
          <p :class="$style.title">
            {{ app.name }}
            <Badge
              size="2"
              :class="
                isInstalled
                  ? $style['badge-installed']
                  : app.status === AppStatus.Available
                    ? $style['badge-available']
                    : $style['badge-coming']
              "
            >
              {{
                isInstalled
                  ? 'Installed'
                  : app.status === AppStatus.Available
                    ? 'Available'
                    : 'Coming soon'
              }}
            </Badge>
          </p>
          <p :class="$style.subtitle">{{ app.subTitle }}</p>
        </div>
      </div>
      <Button
        v-if="!store.workspace?.isPremium && store.apps.length > 0"
        theme="neutral"
        size="2"
        @click="handleInstall"
      >
        Upgrade
      </Button>
      <div v-else-if="isInstalled" :class="$style['cta-container']">
        <Button
          theme="danger"
          variant="soft"
          size="2"
          :loading="store.isStatus('deleting')"
          @click="handleUninstall"
        >
          Remove
        </Button>
        <Button theme="neutral" size="2" @click="$router.push(app.url)">
          Open app
        </Button>
      </div>
      <Button
        v-else-if="app.status !== AppStatus.Available"
        theme="neutral"
        size="2"
        @click="handleInstall"
      >
        Notify me
      </Button>
      <Button
        v-else-if="!isInstalled"
        theme="neutral"
        size="2"
        :loading="store.isStatus('posting')"
        @click="handleInstall"
      >
        Get this app
      </Button>
    </div>
    <p :class="$style['app-description']">
      {{ app.description }}
    </p>
    <div :class="$style['app-image-container']">
      <div
        v-if="app.status === AppStatus.Available"
        :class="$style.coverImage"
      ></div>
      <div v-else :class="$style['coming-soon']">
        <Icon icon="brokenChainLink" />
        <p :class="$style.title">Coming soon!</p>
        <p :class="$style.subtitle">Stay tuned for future updates!</p>
      </div>
    </div>
    <div :class="$style['app-features']">
      <div
        v-for="feature in features"
        :key="feature.title"
        :class="$style['feature-wrapper']"
      >
        <div :class="$style['icon-container']">
          <Icon :icon="feature.icon" :size="20" />
        </div>
        <div :class="$style.feature">
          <p :class="$style['feature-title']">
            {{ feature.title }}
          </p>
          <p :class="$style['feature-subtitle']">
            {{ feature.subtitle }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style module lang="scss">
.app-container {
  width: 556px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .app-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-wrapper {
      display: flex;
      gap: 16px;

      .app-icon-container {
        border-radius: 12px;
        background: var(--neutral-alpha-3);
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-wrapper {
          height: 38px;
          width: 38px;
          background-color: var(--colors-basic-white);
          border-radius: 999px;
          filter: drop-shadow(0px 0px 25.2px rgba(31, 35, 39, 0.14))
            drop-shadow(0px 0.4px 1px rgba(0, 0, 0, 0.08));
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .cta-container {
      display: flex;
      gap: 8px;
    }

    .app-title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: var(--neutral-12);

      .title {
        @extend .semibold-6;
      }

      .subtitle {
        @extend .medium-2;
      }
    }

    .badge-installed {
      background: var(--accent-alpha-3);
      color: var(--accent-alpha-11);
    }

    .badge-coming {
      background: var(--neutral-alpha-3);
      color: var(--neutral-alpha-11);
    }

    .badge-available {
      background: var(--success-alpha-3);
      color: var(--success-alpha-11);
    }
  }

  .app-description {
    @extend .regular-4;

    color: var(--neutral-12);
  }

  .app-image-container {
    padding: 32px 28px 0px 28px;
    border-radius: 16px;
    background-color: var(--panel-3);

    .coverImage {
      background-image: v-bind('cover');
      background-position: start;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 312px;
      border-radius: 4px;
      box-shadow:
        0px 0px 25px -5px rgba(0, 0, 0, 0.1),
        0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    }

    .coming-soon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 66px;
      margin-bottom: 50px;
      gap: 4px;

      i {
        margin-bottom: 20px;
      }

      .title {
        @extend .semibold-3;
        color: var(--neutral-12);
      }

      .subtitle {
        @extend .regular-3;
        color: var(--neutral-11);
      }
    }
  }

  .app-features {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .feature-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .icon-container {
        flex-shrink: 0;
        background: var(--neutral-alpha-3);
        height: 30px;
        width: 30px;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .feature {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .feature-title {
          @extend .medium-4;

          color: var(--neutral-12);
        }

        .feature-subtitle {
          @extend .regular-3;

          color: var(--neutral-11);
        }
      }
    }
  }
}
</style>
