<script setup lang="ts">
import {
  ActiveAppKey,
  Avatar,
  Icon,
  Popover,
  UpgradePopupKey,
} from '@/components/common'
import BillingBanner from '@/components/Dashboard/BillingBanner.vue'
import ProfileMenu from '@/components/Dashboard/ProfileMenu.vue'
import WorkspaceMenu from '@/components/Dashboard/WorkspaceMenu.vue'
import UpgradeFlow from '@/components/payment/UpgradeFlow.vue'
import useWebsockets from '@/composables/websockets.ts'
import type App from '@/models/app'
import { AppKey } from '@/models/app'
import { UnreadNotificationSummary } from '@/models/notification.ts'
import { useCrmStore } from '@/modules/crm/store'
import { useTasksStore } from '@/modules/tasks/store'
import { useCollabStore } from '@/modules/collab/store'
import { useApplicationStore } from '@/store'
import { useWorkspaceStore } from '@/store/workspace'
import { useInboxStore } from '@modules/inbox/store'
import { clsx } from 'clsx'
import { storeToRefs } from 'pinia'
import { computed, onMounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Menu } from './constants'
import $style from './styles.module.scss'

const route = useRoute()
const router = useRouter()
const socket = useWebsockets()
const upgradeFlow = ref<typeof UpgradeFlow>()

const workspaceId = Number(route.params.workspaceId)

const appStore = useApplicationStore()
const workspaceStore = useWorkspaceStore()
workspaceStore.setCurrentWorkspace(workspaceId)
workspaceStore.getMembers()
workspaceStore.getUnreadNotificationSummary()
workspaceStore.getApps().then((data) => {
  workspaceStore.initializeApps()
  if (data.length === 0) {
    router.replace({ name: 'OnboardingMarketplace' })
  } else if (route.matched.length === 1) {
    // don't redirect to app if user is landing on a page
    router.push(data[0].url)
  }
})

const { unreadNotificationSummary } = storeToRefs(workspaceStore)

const subMenus = computed<Record<AppKey, Menu>>(() => ({
  inbox: useInboxStore().menuItems,
  crm: useCrmStore().menuItems,
  projectManagement: useTasksStore().menuItems,
  wiki: useCollabStore().menuItems,
  settings: { children: [] },
}))

const activeApp = computed<App | null>(() => {
  if (workspaceStore.apps.length === 0) return null
  if (route.matched.length > 1) {
    const name = route.matched[1].name
    const index = workspaceStore.apps.findIndex((app) => app.url.name === name)
    if (index >= 0) {
      return workspaceStore.apps[index]
    }
  }
  return null
})

onMounted(() => {
  socket &&
    socket
      .private(`notifications.${workspaceId}.${appStore.user?.id}`)
      .listen(
        '.UnreadNotificationCountChanged',
        (data: UnreadNotificationSummary) =>
          (unreadNotificationSummary.value = data),
      )
})

provide('workspaceId', workspaceId)
provide(UpgradePopupKey.show, () => upgradeFlow.value?.triggerUpgrade())
provide(ActiveAppKey, activeApp)
provide('socket', socket)

const isIconActive = (
  appKey: string,
  subItemUrl: {
    name: string
    params?: {
      teamId?: number
    }
  },
): boolean => {
  if (appKey === 'projectManagement') {
    return route.params.teamId == subItemUrl?.params?.teamId?.toString()
  }
  return route.name == subItemUrl.name
}
</script>
<template>
  <UpgradeFlow ref="upgradeFlow" />
  <Transition name="slide-left">
    <nav v-if="appStore.sidebars.left" :class="$style.navigation">
      <Popover placement="right-start">
        <template #content> <WorkspaceMenu /> </template>
        <div :class="$style.topBarWrapper">
          <div :class="$style.topBarContent">
            <Avatar
              v-if="workspaceStore.workspace?.logo"
              :title="workspaceStore.workspace?.title"
              :src="workspaceStore.workspace?.logo"
              :size="32"
              :radius="10"
            />
            <div v-else :class="$style.workspaceLogo">
              <Icon icon="block" :size="18" />
            </div>
            <div>
              <h4 :class="$style.triggerTitle">
                {{ workspaceStore.workspace?.title }}
              </h4>
              <p :class="$style.triggerSubtitle">Change workspace</p>
            </div>
          </div>
          <Icon icon="chevronGrabberVertical" :size="20" />
        </div>
      </Popover>
      <ul :class="$style.content">
        <!-- <li :class="$style.item">
          <Icon icon="magnifyingGlass" :size="18" />
          <span :class="$style.label">Search</span>
        </li> -->
        <li>
          <router-link :class="$style.item" :to="{ name: 'Notifications' }">
            <Icon icon="bell2" :size="20" />
            <span :class="$style.label">Notifications</span>

            <span
              v-if="unreadNotificationSummary.total"
              :class="$style.remarks"
            >
              {{ unreadNotificationSummary.total }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link :class="$style.item" :to="{ name: 'Marketplace' }">
            <Icon icon="circlePlus" :size="20" />
            <span :class="$style.label">Add new app</span>
          </router-link>
        </li>
        <li :class="$style.gap" />
        <li :class="$style.gap" />
        <p :class="$style.menuTitle">Apps</p>
        <li v-for="app in workspaceStore.apps" :key="app.id">
          <router-link :to="app.url" :class="$style.item">
            <span
              :class="[
                activeApp?.id == app.id && $style.iconActive,
                $style.icon,
              ]"
            >
              <Icon :icon="app.icon" :size="20" />
            </span>

            <span :class="$style.label">
              {{ app.name }}
              <Icon
                v-if="activeApp?.id == app.id"
                icon="chevronDownSmall"
                :size="18"
              />
              <Icon v-else icon="chevronRightSmall" :size="18" />
            </span>

            <span
              v-if="(subMenus[app.key].badge || 0) > 0"
              :class="$style.remarks"
            >
              {{
                subMenus[app.key].badge || 0 > 100
                  ? '100+'
                  : subMenus[app.key].badge
              }}
            </span>
          </router-link>
          <Transition name="navigation-submenu">
            <ul
              v-if="
                (activeApp?.key === app.key &&
                  subMenus[app.key].children.length > 0) ||
                subMenus[app.key].children
                  .map((r) => r.url.name)
                  .includes(route.name as string)
              "
              :class="clsx($style.content, $style.subMenu)"
            >
              <li :class="$style.divider"></li>

              <li v-for="item in subMenus[app.key].children" :key="item.icon">
                <router-link :to="item.url" :class="$style.item">
                  <span
                    :class="[
                      route.name == item.url.name && $style.iconActive,
                      $style.icon,
                    ]"
                  >
                    <Icon :icon="item.icon" :size="20" />
                  </span>
                  <span :class="$style.label">{{ item.title }}</span>
                </router-link>

                <ul
                  v-if="item.children && item.children?.length"
                  :class="clsx($style.content, $style.subMenu)"
                >
                  <li :class="$style.divider"></li>
                  <li v-for="subItem in item.children" :key="subItem.icon">
                    <router-link :to="subItem.url" :class="$style.item">
                      <span
                        :class="[
                          $style.icon,
                          isIconActive(app.key, subItem.url) &&
                            $style.iconActive,
                        ]"
                      >
                        <Icon :icon="subItem.icon" :size="20" />
                      </span>
                      <span :class="$style.label">{{ subItem.title }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </Transition>
        </li>
      </ul>
      <BillingBanner v-if="!workspaceStore.workspace?.isPremium" />
      <Popover placement="right-start">
        <template #content> <ProfileMenu /> </template>
        <div :class="$style.topBarWrapper" class="">
          <div :class="$style.topBarContent">
            <Avatar
              :title="appStore.user?.name"
              :src="appStore.user?.avatar"
              :size="32"
            />
            <div>
              <h4 :class="$style.triggerTitle">
                {{ appStore.user?.name }}
              </h4>
              <p :class="$style.triggerSubtitle">{{ appStore.user?.email }}</p>
            </div>
          </div>
        </div>
      </Popover>
    </nav>
  </Transition>
  <router-view v-if="workspaceStore.apps.length > 0" />
</template>
