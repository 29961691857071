<script setup lang="ts">
import {
  AssigneeMenu,
  Button,
  DropDown,
  FileField,
  Popover,
  RichEditor,
} from '@/components/common'
import EmojiPicker from '@/components/common/EmojiPicker.vue'
import { useWorkspaceApi } from '@/composables/api'
import type User from '@/models/user'
import {
  CommandMenu,
  MatchType,
  type NewState,
} from '@/utils/prosemirror/CommandMenu'
import { computed, reactive, ref, toRefs } from 'vue'
import { TaskComment } from '../models/taskComment'
import Attachments from '@modules/tasks/components/Attachment/Attachments.vue'
import { renderMarkdown } from '@/utils/inbox'

const props = defineProps<{
  taskId: number
  parentComment?: TaskComment
}>()
const { taskId, parentComment } = toRefs(props)

const emit = defineEmits<{
  (e: 'send', data: TaskComment): void
  (e: 'cancelReply'): void
}>()

interface TaggingState {
  isOpen: boolean
  range: NewState['range']
}

const message = ref('')
const editorRef = ref<typeof RichEditor>()
const taggingRef = ref<typeof DropDown>()
const editorFocused = ref<boolean>(false)

const { api, isStatus } = useWorkspaceApi()

const tagging = reactive<TaggingState>({
  isOpen: false,
  range: null,
})

const attachments = reactive<{
  files: File[]
  images: File[]
}>({
  files: [],
  images: [],
})

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']

const acceptableFiles = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/mp4',
  'image/*',
]

const plugins = () => {
  return [
    CommandMenu(
      MatchType.Tagging,
      (state) => {
        tagging.isOpen = state.active
        tagging.range = state.range

        if (taggingRef.value) {
          taggingRef.value.performSearch(state.text)
        }
      },
      ({ up, down, enter }) => {
        if (!taggingRef.value) {
          return
        }

        if (up) return taggingRef.value.handleUpKey()
        if (down) return taggingRef.value.handleDownKey()

        if (enter) {
          taggingRef.value.handleEnter()
        }
      },
    ),
  ]
}

const handleEmoji = (emoji: any) => {
  editorRef.value && editorRef.value.insertText(emoji.native)
}

const handleFiles = (data: File | File[]) => {
  const files = data as File[]
  attachments.files = [
    ...attachments.files,
    ...files.filter((f) => !imageTypes.includes(f.type)),
  ]
  attachments.images = [
    ...attachments.images,
    ...files.filter((f) => imageTypes.includes(f.type)),
  ]
}

const handleSend = () => {
  const formData = new FormData()
  formData.append('content', message.value)
  if (parentComment.value) {
    formData.append('parentId', `${parentComment.value.id}`)
  }
  attachments.images.forEach((image) => {
    formData.append('attachments[images][]', image)
  })
  attachments.files.forEach((file) =>
    formData.append('attachments[files][]', file),
  )

  api.task.addTaskComment(taskId.value, formData).then((data) => {
    attachments.images = []
    attachments.files = []
    editorRef.value && editorRef.value.clear()
    emit('send', data as TaskComment)
  })
}
const deleteImage = (index: number) => {
  attachments.images.splice(index, 1)
}
const deleteFile = (index: number) => {
  attachments.files.splice(index, 1)
}

const isValidMessage = computed(
  () =>
    message.value.length > 0 ||
    attachments.files.length > 0 ||
    attachments.images.length > 0,
)

const handleUserTagged = (selected: User[]) => {
  const user = selected?.[0]

  if (!editorRef.value || !user?.id) {
    return
  }

  editorRef.value.insertTaggingUser(user, tagging.range)
  tagging.isOpen = false
  taggingRef.value && taggingRef.value.performSearch('')
}
</script>
<template>
  <div :class="[$style.composer, editorFocused && $style.focus]">
    <Popover
      placement="top-start"
      :show="tagging.isOpen"
      :class="$style.empty"
      @close-outside="tagging.isOpen = false"
    >
      <template #content>
        <AssigneeMenu ref="taggingRef" only-menu @update="handleUserTagged">
          <template #empty>
            <div :class="$style.emptyList">
              No results found

              <Button
                size="2"
                icon="crossSmall"
                variant="ghost"
                theme="neutral"
                @click.prevent="tagging.isOpen = false"
              />
            </div>
          </template>
        </AssigneeMenu>
      </template>
    </Popover>
    <div v-if="parentComment" :class="$style['parent-comment']">
      <p v-html="renderMarkdown(parentComment.content)" />
      <Button
        icon="crossSmall"
        variant="ghost"
        theme="neutral"
        size="1"
        @click="() => emit('cancelReply')"
      />
    </div>
    <RichEditor
      ref="editorRef"
      v-model="message"
      :class="$style.editor"
      placeholder="Leave a comment"
      :plugins="plugins()"
      @drop-files="handleFiles"
      @drop-images="handleFiles"
      @focus="editorFocused = true"
      @blur="editorFocused = false"
    />

    <Attachments
      :files="attachments.files"
      :images="attachments.images"
      :removable="true"
      @remove-file="deleteFile"
      @remove-image="deleteImage"
    />

    <div :class="$style.controls">
      <div :class="$style.actions">
        <Popover placement="top-end">
          <Button icon="emojiSmile" variant="ghost" theme="neutral" size="2" />
          <template #content="{ close }">
            <emoji-picker
              @select="
                (data) => {
                  handleEmoji(data)
                  close()
                }
              "
            />
          </template>
        </Popover>

        <file-field
          :multiple="true"
          :accept="acceptableFiles.join(',')"
          @update:model-value="handleFiles"
        >
          <Button
            as="i"
            icon="paperclip2"
            variant="ghost"
            theme="neutral"
            size="2"
          />
        </file-field>
      </div>

      <Button
        :class="$style.send"
        :loading="isStatus('posting')"
        size="2"
        theme="neutral"
        variant="ghost"
        icon="sendMessage"
        :disabled="!isValidMessage"
        @click="handleSend"
      />
    </div>
  </div>
</template>
<style module lang="scss">
.composer {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-alpha-2);
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 32px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--neutral-alpha-3);
  }

  &.note {
    background: var(--warning-alpha-2);
  }

  .parent-comment {
    display: flex;
    padding-left: 6px;
    border-left: 2px solid var(--neutral-alpha-12);
    margin-bottom: 16px;
    justify-content: space-between;

    p {
      @extend .medium-3;
    }

    i {
      color: var(--neutral-alpha-11);
    }
  }

  .editor {
    margin-bottom: 8px;
    z-index: 1;

    :global(.rich-text) {
      padding-top: 4px;
      min-height: 60px;
    }
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .actions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 4px;
    margin-bottom: -4px;
  }
  .send {
    border-radius: 999px;
  }

  .attachments {
    z-index: 1;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
}

.emptyList {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @extend .medium-3;
}
</style>
