<script setup lang="ts">
import {
  Label,
  Button,
  AssigneeBadge,
  AutoComplete,
  Avatar,
  MenuItem,
  Icon,
  TextField,
  Popover,
  AssigneeMenu,
  AddTag,
  ResourceSelector,
  Tag,
} from '@/components/common'
import type Contact from '@/models/contact'
import { reactive } from 'vue'
import { useCrmStore } from '../store'
import type { Company } from '@/models/company'
import type User from '@/models/user'
import type { Pipeline } from '../models/pipeline'
import { LexoRank } from 'lexorank'
import StageIndicator from './StageIndicator.vue'
import type { Tag as TagModel } from '@/models/tag'
import { useCrmPipelineStore } from '../pipelineStore'

const props = defineProps<{
  pipeline?: Pipeline
}>()

const pipelineStore = useCrmPipelineStore()
const store = useCrmStore()
store.tags.length === 0 && store.loadTags()

const state = reactive<{
  contacts: Contact[]
  contact: Contact | null
  isNew: boolean
  name?: string
  email?: string
  companies: Company[]
  company: Company | null
  assignee: User[]
  pipelines: Pipeline[]
  tags: TagModel[]
}>({
  contacts: [],
  contact: null,
  companies: [],
  company: null,
  isNew: !props.pipeline,
  name: '',
  email: '',
  assignee: [],
  pipelines: props.pipeline ? [props.pipeline] : [],
  tags: [],
})
const loadContacts = (search: string) => {
  store.api.crm
    .loadContacts({ search })
    .then((data) => (state.contacts = data.data))
}

const loadCompanies = (search: string) => {
  store.api.company
    .loadCompanies({ search })
    .then((data) => (state.companies = data.data))
}

const addCompany = (name: string) => {
  store.api.company.createCompany(name).then((data) => {
    state.company = data
    state.companies.push(data)
  })
}

const createNewContact = (email: string) => {
  state.isNew = true
  state.name = email
}

const addContact = () => {
  store.api.crm
    .addContact({
      contactId: state.contact?.id,
      ...(state.isNew && {
        name: state.name,
        email: state.email,
        companyId: state.company?.id,
      }),
      assigneeId: state.assignee.length ? state.assignee[0].id : undefined,
      pipelineIds: state.pipelines.map((p) => p.id),
      tagIds: state.tags.map((t) => t.id),
      rank: LexoRank.min().toString(),
    })
    .then((contact) => {
      if (props.pipeline) pipelineStore.addContact(contact)
      else store.addContact(contact)
    })
}
</script>
<template>
  <div :class="[$style.content, 'popup-content']">
    <Label v-if="!state.isNew" as="div" :class="$style.field">
      Contact
      <AutoComplete
        size="2"
        :is-loading="store.isStatus('loading')"
        placeholder="Start typing a contact name or email"
        :items="state.contacts ?? []"
        item-key="id"
        title-key="name"
        subtitle-key="email"
        clear-on-select
        @select="(item) => (state.contact = item)"
        @load="loadContacts"
      >
        <template #icon="{ item }">
          <Avatar :title="item.name || item.email" :size="32" />
        </template>
        <template #noResults="{ search }">
          <MenuItem
            :title="`Create new contact &quot;${search}&quot;`"
            @click="createNewContact(search)"
          >
            <template #icon>
              <Icon icon="plusSmall" :size="20" />
            </template>
          </MenuItem>
        </template>
      </AutoComplete>
    </Label>
    <template v-else>
      <Label :class="$style.field">
        Full name
        <TextField v-model="state.name" size="2" placeholder="John Doe" />
      </Label>
      <Label :class="$style.field">
        Email
        <TextField
          v-model="state.email"
          type="email"
          size="2"
          placeholder="johndoe@gmail.com"
        />
      </Label>
      <Label as="div" :class="$style.field">
        Company name
        <AutoComplete
          size="2"
          placeholder="Company"
          :items="state.companies ?? []"
          item-key="id"
          title-key="name"
          clear-on-select
          :is-loading="store.isStatus('loading')"
          @select="(item) => (state.company = item)"
          @load="loadCompanies"
        >
          <template #noResults="{ search }">
            <MenuItem
              :title="`Create new company &quot;${search}&quot;`"
              @click="addCompany(search)"
            >
              <template #icon>
                <Icon
                  :icon="
                    store.isStatus('posting') ? 'vanillaIcon' : 'plusSmall'
                  "
                  :size="20"
                />
              </template>
            </MenuItem>
          </template>
        </AutoComplete>
      </Label>
    </template>
    <Popover placement="bottom-start">
      <template #content>
        <AssigneeMenu
          :assignees="state.assignee"
          :single-selection="true"
          @update="(data) => (state.assignee = data)"
        />
      </template>
      <AssigneeBadge :users="state.assignee" />
    </Popover>
    <Popover :disabled="!!props.pipeline" placement="bottom-start">
      <template #content>
        <ResourceSelector
          :items="store.pipelines"
          :selected="state.pipelines"
          item-key="id"
          title-key="name"
          selection="multi"
          @update="(data) => (state.pipelines = data)"
        />
      </template>
      <Button
        :class="[$style.pipelineSelector, props.pipeline && $style.disable]"
        size="2"
        theme="neutral"
        variant="ghost"
        icon="plusSmall"
      >
        <template v-if="state.pipelines.length === 1" #icon>
          <StageIndicator
            :size="16"
            :stage="0"
            :total-stages="state.pipelines[0].stageCount || 0"
          />
        </template>
        {{
          state.pipelines.length
            ? state.pipelines.length > 1
              ? `${state.pipelines.length} pipelines`
              : state.pipelines[0].name
            : 'Add pipeline'
        }}</Button
      >
    </Popover>
    <hr />
    <Popover>
      <template #content>
        <ResourceSelector
          :items="store.tags"
          :selected="state.tags"
          item-key="id"
          title-key="name"
          selection="multi"
          @update="(data) => (state.tags = data)"
        />
      </template>
      <div :class="$style.tags">
        <AddTag v-if="state.tags.length === 0" size="1" icon="plusSmall"
          >Add Tag</AddTag
        >
        <Tag
          v-for="tag in state.tags"
          :key="tag.id"
          size="2"
          variant="outline"
          >{{ tag.name }}</Tag
        >
      </div>
    </Popover>
  </div>
  <div :class="['popup-actions', 'popup-content']">
    <Button
      size="3"
      theme="neutral"
      variant="solid"
      :loading="store.isStatus('posting') || pipelineStore.isStatus('posting')"
      @click="addContact"
      >Add contact</Button
    >
  </div>
</template>
<style module lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 498px;
  row-gap: 24px;
}
.field {
  align-self: stretch;
}
.pipelineSelector {
  padding-left: 8px;
  padding-right: 8px;
}
.disable {
  pointer-events: none;
}
.tags {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}
</style>
