<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useWorkspaceStore } from '@/store/workspace'
import { useTasksTeamStore } from './teamStore'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import {
  Button,
  Popover,
  Popup,
  ResourceSelector,
  type DropDownItem,
} from '@/components/common'
import DropDown from '@/components/common/DropDown.vue'
import BoardView from './components/BoardView/index.vue'
import ListView from './components/ListView/index.vue'
import { useRouter } from 'vue-router'
import { useTasksStore } from './store'
import AddTask from './components/AddTask.vue'
import FilterMenu from './components/FilterMenu.vue'
import FilterSummary from './components/FilterSummary.vue'
import Members from './components/BoardView/Members.vue'
import { TaskSearch } from './types'

const router = useRouter()
const teamId = ref(Number(router.currentRoute.value.params.teamId))

const addTaskRef = ref<typeof Popup>()
const store = useTasksTeamStore()

if (!store.team || store.team.id !== teamId.value) {
  store.$reset()
  store.loadTeam(teamId.value).then(() => store.loadTasks())
}
if (store.team && !store.tasks.length) {
  store.loadTasks()
}

watch(
  () => store.team,
  (team) => {
    if (team) {
      document.title = `${store.team?.name}'s tasks`
    } else {
      document.title = 'Team'
    }
  },
  { immediate: true },
)
watch(
  () => router.currentRoute.value.params.teamId,
  () => {
    teamId.value = Number(router.currentRoute.value.params.teamId)
    if (!store.team || store.team.id !== teamId.value) {
      store.$reset()
      store.loadTeam(teamId.value).then(() => store.loadTasks())
    }
    if (store.team && !store.tasks.length) {
      store.loadTasks()
    }
  },
)

watch(
  () => store.isAddTaskOpen,
  (value) => {
    value && addTaskRef.value?.show()
    !value && addTaskRef.value?.hide()
  },
)

const taskStore = useTasksStore()
const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore

const displayView = ref<'list' | 'board'>('board')

const menu: DropDownItem[] = [
  {
    id: 'column',
    icon: 'slideTallAdd',
    title: 'Column',
    handler: store.addStatus,
  },
  {
    id: 'task',
    icon: 'layoutGrid2',
    title: 'Task',
    handler: () => {
      store.selectedStatus = undefined
      store.isAddTaskOpen = true
    },
  },
]

const searchState = reactive<TaskSearch>({})
watchDebounced(
  searchState,
  () => {
    store.loadTasks(searchState)
  },
  { debounce: 200 },
)

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Tasks`,
    url: { name: 'Tasks' },
  },
  {
    title: 'Teams',
    url: { name: 'TasksTeams' },
  },
  {
    title: store.team?.name || '',
  },
])
</script>
<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <div :class="$style.pageActions">
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          @click="displayView = displayView === 'list' ? 'board' : 'list'"
          >{{ displayView === 'list' ? 'Board' : 'List' }} View</Button
        >
        <DropDown :width="160" :menu="menu" :close-on-select="true">
          <Button size="2" variant="outline" theme="neutral">Create</Button>
        </DropDown>
      </div>
    </template>
    <div :class="$style.topBar">
      <div :class="$style.rightSide">
        <Popover placement="bottom-start">
          <template #content>
            <ResourceSelector
              :items="taskStore.teams"
              item-key="id"
              title-key="name"
            />
          </template>
          <Button
            :class="$style.selector"
            icon-right="chevronGrabberVertical"
            variant="ghost"
            theme="neutral"
            size="2"
            >{{ store.team?.name }}</Button
          >
        </Popover>
        <span :class="$style.contacts">{{ store.tasks.length }} tasks</span>
      </div>
      <div :class="$style.rightSide">
        <Members v-if="store.team" :team="store.team" />
        <Popover placement="bottom-end">
          <template #content>
            <FilterMenu
              mode="team"
              :search="searchState"
              @update="(data) => Object.assign(searchState, data)"
            />
          </template>
          <Button variant="soft" theme="neutral" size="2" icon="filter1"
            >Filters</Button
          >
        </Popover>
      </div>
    </div>
    <FilterSummary
      v-if="Object.values(searchState).filter(Boolean).length > 0"
      mode="team"
      :search="searchState"
      @update="(data) => Object.assign(searchState, data)"
    />

    <BoardView v-if="displayView === 'board'" />
    <ListView v-if="displayView === 'list'" />

    <Popup
      ref="addTaskRef"
      :persistent="true"
      @hide="store.isAddTaskOpen = false"
    >
      <AddTask
        v-if="store.team"
        :team="store.team"
        :status="store.selectedStatus"
        :team-editable="true"
        :description-height="200"
        @cancel="store.isAddTaskOpen = false"
      />
    </Popup>
  </Page>
</template>
<style module lang="scss">
.pageActions {
  display: flex;
  column-gap: 12px;
}
.topBar {
  margin: 32px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rightSide {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .selector {
    @extend .semibold-4;
    padding-left: 8px;
    padding-right: 8px;
  }

  .contacts {
    @extend .regular-3;
    color: var(--neutral-11);
  }
}
</style>
