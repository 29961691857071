<script setup lang="ts">
import { AssigneeBadge, AssigneeMenu, Popover } from '@/components/common'
import type { PipelineContact } from '../../models/pipelineContact'
import { useCrmPipelineStore } from '../../pipelineStore'

defineProps<{
  contact: PipelineContact
}>()

const store = useCrmPipelineStore()
</script>
<template>
  <div :class="$style.container">
    <div :class="$style.titleArea">
      <div :class="$style.names">
        <b :class="$style.name">{{ contact.name || contact.email }}</b>
        <p :class="$style.companyName">{{ contact.company?.name }}</p>
      </div>
      <Popover>
        <template #content>
          <AssigneeMenu
            :single-selection="true"
            :assignees="
              contact.pipelineAssignee ? [contact.pipelineAssignee] : []
            "
            @update="(data) => store.assignContact(contact, data[0])"
          />
        </template>
        <AssigneeBadge
          :users="contact.pipelineAssignee ? [contact.pipelineAssignee] : []"
          :avatar-only="true"
        />
      </Popover>
    </div>
    <p :class="$style.email">{{ contact.email }}</p>
  </div>
</template>
<style module lang="scss">
.container {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--background-reverse-1);
  box-shadow: 0px 0.3px 0.3px 0.01px rgba(0, 0, 0, 0.16);
  cursor: grab;
  transition: background-color 300ms ease-out;
  &:hover {
    background-color: var(--neutral-alpha-2);
  }
  .titleArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .names {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }

    .name {
      @extend .medium-3;
    }

    .companyName {
      @extend .regular-2;
      color: var(--neutral-11);
    }
  }

  .email {
    @extend .medium-2;
    margin-top: 8px;
  }
}
</style>
