<script setup lang="ts">
import { ref, toRefs, type InputHTMLAttributes } from 'vue'
import Icon from './Icon.vue'

defineOptions({ inheritAttrs: false })

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  variant?: 'outline' | 'soft'
  size?: '1' | '2' | '3' | '4' | '5'
  modelValue?: string | number | unknown
  multiLine?: boolean
  error?: string
  hint?: string
}
const inputRef = ref<HTMLInputElement | HTMLTextAreaElement | null>(null)

defineExpose({
  focus: () => inputRef.value?.focus(),
})

const props = defineProps<Props>()
const { variant, modelValue, multiLine } = toRefs(props)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const handleChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  emit('update:modelValue', target.value)
}
</script>
<template>
  <div
    :class="[
      $style.input,
      $style[variant || 'outline'],
      $style[`size-${size || 1}`],
      error && $style.error,
      $attrs.class,
    ]"
  >
    <div v-if="$slots.pre" :class="$style.pre">
      <slot name="pre"></slot>
    </div>
    <component
      :is="multiLine ? 'textarea' : 'input'"
      ref="inputRef"
      :value="modelValue"
      v-bind="$attrs"
      @input="handleChange"
    />
    <div v-if="$slots.post" :class="$style.post">
      <slot name="post"></slot>
    </div>
  </div>
  <sub v-if="hint || error" :class="[$style.subscript, error && $style.error]">
    <Icon icon="circleExclamation" :size="16" />
    {{ hint || error }}
  </sub>
</template>
<style module lang="scss">
.input {
  display: flex;
  align-items: stretch;
  column-gap: 8px;
  transition: all 150ms;
  box-sizing: border-box;
  cursor: text;

  &:has(> input:disabled) {
    background-color: var(--panel-3);

    > input {
      color: var(--neutral-alpha-8);
    }
  }

  > input,
  > textarea {
    flex-grow: 1;
    background: none;
    outline: none;
    border: none;
    resize: vertical;
    font-family: inherit;
    color: var(--neutral-alpha-12);

    &:-webkit-autofill {
      color: unset !important;
      background-color: unset !important;
    }
  }
}

.pre {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscript {
  display: flex;
  align-items: center;
  margin-top: 8px;
  @extend .regular-2;
  column-gap: 4px;
  color: var(--neutral-11);
}

.outline {
  border: 1px solid var(--neutral-alpha-6);

  &::placeholder {
    color: var(--neutral-alpha-11);
  }

  &:hover {
    background-color: var(--neutral-alpha-2);
  }

  &:focus-within {
    border-color: var(--neutral-12);
    box-shadow: 0px 0px 0px 2px var(--neutral-alpha-4);
  }

  &.error {
    border-color: var(--danger-9);
    box-shadow: 0px 0px 0px 2px var(--danger-alpha-4);
  }
}

.soft {
  background-color: var(--neutral-alpha-3);

  &::placeholder {
    color: var(--neutral-alpha-11);
  }

  &:hover {
    background-color: var(--neutral-alpha-4);
  }

  &:focus-within {
    background-color: unset;
    outline: 1px solid var(--neutral-alpha-6);
  }

  &.error {
    background-color: var(--danger-alpha-3);
  }
}

.size {
  &-1 {
    border-radius: 6px;
    padding: 0px 8px;
    > input,
    > textarea {
      @extend .regular-3;
      padding: 6px 0px;
    }
  }

  &-2 {
    border-radius: 8px;
    padding: 0px 9px;
    > input,
    > textarea {
      @extend .regular-3;
      padding: 9px 0px;
    }
  }

  &-3 {
    border-radius: 8px;
    padding: 0px 12px;
    > input,
    > textarea {
      @extend .regular-4;
      padding: 9px 0px;
    }
  }

  &-4 {
    border-radius: 12px;
    padding: 0px 15px;
    > input,
    > textarea {
      @extend .regular-4;
      padding: 15px 0px;
    }
  }
}
</style>
